import { request } from "./request"
import WOOCOMMERCE from "../env/WOOCOMMERCE"
const { KEY, SECRET } = WOOCOMMERCE.APIAUTH

class WPAPI {
  constructor() {
    this.route = "/wp-json/wc/v3"
  }

  // kupony
  getDiscount(items, coupon) {
    const url = `wp-json/v1/coupons/check-basket-discount`
    const config = {
      method: "POST",
      data: {
        items: items,
        coupon: coupon,
      },
    }
    return request(url, config)
  }

  // ordery
  postCreateOrder(customer, cart, shipping, coupon = {}, token) {
    const url = this.route + `/orders`
    const config = {
      method: "POST",
      withCredentials: true,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        billing: customer,
        shipping: customer,
        customer_note: customer.note,
        line_items: cart,
        shipping_lines: [shipping],
        ...coupon,
      },
    }
    return request(url, config)
  }

  changeOrder(id, data = { status_order: "completed" }) {
    const token = localStorage.getItem("token")
    const url = `wp-json/wp/v2/orders/status/?id=${id}`
    const config = {
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
      data,
    }
    return request(url, config)
  }

  cancelOrder(id, data = { status: "cancelled" }) {
    const url = this.route + `/orders/${id}`
    const config = {
      method: "PUT",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data,
    }

    return request(url, config)
  }
  searchByPaymentId(paymentId) {
    const token = localStorage.getItem("token")
    const url = `wp-json/wp/v2/orders/searchById/?search=${paymentId}`
    const config = {
      method: "GET",
      withCredentials: true,
      headers: { Authorization: `Bearer ${token}` },
    }
    return request(url, config)
  }

  // contact
  sendMail(email, sender, content, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/1436/feedback"
    const data = new FormData()
    data.append("email", email)
    data.append("sender", sender)
    data.append("content", content)

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }

  signToNewsletter(email) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/1451/feedback"
    const data = new FormData()
    data.append("email", email)

    const config = {
      method: "POST",
      multipart: true,
      data,
    }
    return request(url, config)
  }

  getDeliveryOptions() {
    // POST /wp-json/wc/v3/shipping/zones
    // POST /wp-json/wc/v3/shipping/zones/<id>/methods
    const url = "/wp-json/wc/v3/shipping/zones"
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config).then((data) => {
      const [selected] = data.filter((el) => el.name === "Remote")
      const url = `/wp-json/wc/v3/shipping/zones/${selected.id}/methods`
      const config = {
        method: "GET",
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET,
        },
      }
      return request(url, config)
    })
  }

  getCollaborators() {
    const url = `/wp-json/get-contributors/v1/POST`
    const config = {
      method: "POST",
      crossDomain: true,
    }
    return request(url, config)
  }

  ///////VALIDATION/////////
  registerAndGetToken(customer) {
    const url = `wp-json/wp/v2/users/register`
    let newUsername = customer.first_name + customer.last_name
    let newPassword = Math.random().toString(36).substring(7)
    const config = {
      method: "POST",
      data: {
        username: newUsername,
        email: customer.email,
        password: newPassword,
      },
    }
    return request(url, config).then(() => {
      const url = `/wp-json/jwt-auth/v1/token`
      const config = {
        method: "POST",
        data: {
          username: newUsername,
          password: newPassword,
        },
      }
      return request(url, config)
    })
  }

  validateToken(token) {
    const url = `/wp-json/jwt-auth/v1/token/validate`
    const config = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    }
    return request(url, config)
  }

  deleteToken() {
    const token = localStorage.getItem("token")
    const url = `/wp-json/jwt-auth/v1/token/cancellation`
    const config = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    }
    return request(url, config)
  }
}

const WPApi = new WPAPI()

export default WPApi
