import axios from "axios"

import querystring from "query-string"

const redirectUri = "http://gladiators-landing.page.localhost-group.com/"

const DEBUG = false
const instance = axios.create({
  baseURL: "https://rebuilder.localhost-group.com",
  timeout: 1000 * 60,
  headers: {
    "Access-Control-Allow-Origin": redirectUri,
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})

const logger = (data, url) => {
  DEBUG &&
    console.log(url, `\n\t status: ${data.status}`, `\n\t payload: `, data.data)
  return data.data
}

const request = (_url, _config = {}) => {
  DEBUG && console.log(_url, "config", _config)
  let req = {
    url: _url,
    ..._config,
  }
  if (!req.headers) {
    req.headers = {}
  }
  if (_config.multipart) {
    req.headers["Content-Type"] = "multipart/form-data"
  }

  if (_config.query && Object.keys(_config.query).length !== 0) {
    req.url +=
      "?" + querystring.stringify(_config.query, { arrayFormat: "bracket" })
  }

  return instance
    .request(req)
    .then((data) => {
      return logger(data, _url)
    })
    .catch((err) => {
      console.log("err", err)
      return Promise.reject(err)
    })
}

class GITHUBAPI {
  constructor() {}
  exchangeCode(code) {
    const url = "/github/oauth"
    const data = new FormData()
    data.append("code", code)
    data.append("client_id", "485b41b89683709f9b11")
    data.append("client_secret", "2e1f946cd825f034092e158ed3db24184a1ee282")
    const config = {
      method: "POST",
      multipart: true,
      data,
    }
    return request(url, config)
  }

  addCollaborator(nick, product) {
    const url = "/github/oauth"
    const data = new FormData()
    data.append("nick", nick)
    data.append("products", product)
    const config = {
      method: "PUT",
      multipart: true,
      data,
    }
    return request(url, config)
  }
}

const GitHubApi = new GITHUBAPI()

export default GitHubApi
