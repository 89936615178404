import React, { useState, useEffect, useCallback, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"
import { WindowCtx } from "@components/contexted"
import { AnimatedLink } from "@components/shared"

// import { button__yellow } from "@styles/button.module.scss"
import Hamburger from "./Hamburger"
import Menu from "./Menu"
import gladiatorsLogo from "./gladiators_logo.png"
import { INavItem } from "./types"

import {
  header,
  logo,
  nav,
  nav__list,
  nav__list__button,
  nav__list__link,
  selected,
} from "./header.module.scss"

const navLinks: Array<INavItem> = [
  {
    name: "Strona główna",
    url: "/",
  },
  {
    name: "O mnie",
    url: "/#meet",
  },
  {
    name: "Blog",
    url: "/blog",
  },
  {
    name: "Nagrania",
    url: "/records",
  },
  {
    name: "Wydarzenia",
    url: "/events",
  },
  {
    name: "Kontakt",
    url: "/#contact",
  },
]

const Header: React.FC = (): JSX.Element => {
  const { y } = useContext(WindowCtx)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()
  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const handleKeydown = useCallback((e) => {
    if (!isMenuOpen) return
    if (e.which === 27 || e.key === "Escape") toggleMenu()
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", (e: Event) => handleKeydown(e))
    return () =>
      window.removeEventListener("keydown", (e: Event) => handleKeydown(e))
  }, [])
  console.log("path", location.pathname)
  return (
    <header className={header}>
      <Helmet>
        <body className={isMenuOpen ? "blocked" : ""} />
      </Helmet>

      <nav className={nav}>
        <div className={logo}>
          <AnimatedLink to="/">
            <img src={gladiatorsLogo} alt="logo"></img>
          </AnimatedLink>
        </div>
        <ul className={nav__list}>
          {navLinks.map(({ name, url, button }) => {
            const itemClass = button ? nav__list__button : nav__list__link
            return (
              <li key={name}>
                <AnimatedLink
                  to={url}
                  className={
                    (location.pathname === url && !location.hash) ||
                    (url === "/blog" && location.pathname.includes("/blog")) ||
                    (url.includes(location.hash) &&
                      !!location.hash &&
                      url !== "/" &&
                      !button)
                      ? `${itemClass} ${selected}`
                      : itemClass
                  }
                >
                  {name}
                </AnimatedLink>
              </li>
            )
          })}
        </ul>

        <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      </nav>

      <Menu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </header>
  )
}

export default Header
