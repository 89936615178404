import React, { useState, useEffect, useCallback, useRef } from "react"
import { useTransition, animated, config } from "react-spring"

import {
  itemSlider,
  itemSlider__slideWrapper,
  itemSlider__slideWrapper__slide,
  itemSlider__pagination,
  activeSlide,
} from "./slider.module.scss"

const prepareStyles = (styles) => ({
  opacity: styles.opacity,
  transform: styles.scale.interpolate(
    (s) => `scale3d(${s}, ${s}, 1) translateY(-50%)`
  ),
})

const Slider = ({
  items = [],
  observeValue = (item) => item.slug,
  className = "",
  name,
}) => {
  const [index, set] = useState(0)
  const ref = useRef(null)

  const transitions = useTransition(items[index], observeValue, {
    from: {
      opacity: 0,
      scale: 0.7,
      x: -50,
      y: -50,
    },
    enter: {
      opacity: 1,
      scale: 1,
      x: -50,
      y: -50,
    },
    leave: {
      opacity: 0,
      scale: 0.7,
      x: -50,
      y: -50,
    },
    config: config.gentle,
  })

  const nextSlide = useCallback(() => {
    clearTimeout(ref.current)
    set((state) => (state + 1) % items.length)
  }, [])

  useEffect(() => {
    ref.current = setTimeout(() => {
      set((state) => (state + 1) % items.length)
      clearTimeout(ref.current)
    }, 10000)
    return () => {
      clearTimeout(ref.current)
    }
  }, [index])

  return (
    <div className={`${itemSlider} ${className}`}>
      <a onClick={nextSlide} className={itemSlider__slideWrapper}>
        {transitions.map(({ item, props, key }) => (
          <animated.div
            className={itemSlider__slideWrapper__slide}
            key={key}
            style={prepareStyles(props)}
          >
            {item.icon && <img alt={item.title} src={item.icon} />}
            {item.title && <h3>{item.title}</h3>}
            {item.subtitle && <h4>{item.subtitle}</h4>}
            <p>{item.content}</p>
          </animated.div>
        ))}
      </a>
      <div className={itemSlider__pagination}>
        {Array.from({ length: items.length }, (_, i) => i).map((page) => {
          const id = `${name}-${page}`
          return (
            <label
              htmlFor={id}
              key={id}
              className={page === index ? activeSlide : ""}
            >
              <input id={id} type="radio" onClick={() => set(page)} />
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default Slider
