import { request } from "./request"

class PayUAPI {
  constructor() {
    this.route = "/wp-json/payu/v1"
  }
  initPayment(orderId, token) {
    console.log("initPayment")
    const url = this.route + `/get_order/${orderId}`
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: "GET",
    }
    return request(url, config)
  }
  getTransactionStatus(transactionId) {
    const token = localStorage.getItem("token")
    console.log("getTransactionStatus")
    const url = this.route + `/get_payment_status/${transactionId}`
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      method: "GET",
    }
    return request(url, config)
  }
}

const PayUApi = new PayUAPI()

export default PayUApi
