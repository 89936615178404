import React, { useCallback } from "react"
import { AnimatedLink } from "@components/shared"
import { button__secondary__white } from "@styles/button.module.scss"
import { INavItem, IMenu, IClickableDiv } from "./types"

import {
  menu,
  menu__open,
  menu__closed,
  menu__nav,
  menu__nav__list__item,
  menu__nav__list__button,
} from "./header.module.scss"

const navLinks: Array<INavItem> = [
  {
    name: "Strona główna",
    url: "/",
  },
  // {
  //   name: "Zostań gladiatorem",
  //   url: "/#gladiators",
  // },
  {
    name: "O mnie",
    url: "/#meet",
  },
  {
    name: "Blog",
    url: "/blog",
  },
  {
    name: "Nagrania",
    url: "/records",
  },
  {
    name: "Wydarzenia",
    url: "/events",
  },
  {
    name: "Kontakt",
    url: "/#contact",
  },
  // {
  //   name: "pogadajmy",
  //   url: "/#pogadajmy",
  //   button: true,
  // },
]

// uniwersalny
const ClickableDiv: React.FC<IClickableDiv> = (props) => <div {...props} />

const Menu: React.FC<IMenu> = ({ isMenuOpen, toggleMenu }) => {
  const handleLinkClick = useCallback(
    (e) => {
      if (e.target.hasAttribute("href")) toggleMenu()
    },
    [isMenuOpen]
  )

  return (
    <ClickableDiv
      className={isMenuOpen ? menu__open : menu__closed}
      onClick={handleLinkClick}
    >
      <nav className={menu__nav}>
        <ul>
          {navLinks.map(({ name, url, button }) => {
            const itemClass = button
              ? button__secondary__white
              : menu__nav__list__item

            return (
              <li key={name}>
                <AnimatedLink to={url} className={itemClass}>
                  {name}
                </AnimatedLink>
              </li>
            )
          })}
        </ul>
      </nav>
    </ClickableDiv>
  )
}

export default Menu
