// extracted by mini-css-extract-plugin
export const sideLeft = "layout-module--sideLeft--3T-vO";
export const sideRight = "layout-module--sideRight--2srqy";
export const main = "layout-module--main--1HTfh";
export const plam__right__first = "layout-module--plam__right__first--1h-wM";
export const plam__right__second = "layout-module--plam__right__second--cAop2";
export const plam__right__third = "layout-module--plam__right__third--wXMKN";
export const plam__left__first = "layout-module--plam__left__first--3KylC";
export const plam__left__second = "layout-module--plam__left__second--3CwQL";
export const plam__left__third = "layout-module--plam__left__third--3LnFd";
export const small = "layout-module--small--BCwrM";