import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import HelmetSVG from "./helmet.svg"
import FacebookSVG from "./facebook.svg"
import GithubSVG from "./github.svg"

import {
  app__footer,
  app__footer__wrapper,
  // app__footer__wrapper__mobile,
  // app__footer__wrapper__mlinks,
  // app__footer__wrapper__mlinks__regulations,
  // app__footer__wrapper__mobile__icons,
  app__footer__wrapper__content,
  app__footer__wrapper__social,
  app__footer__copyFoot,
} from "./footer.module.scss"
import { bool } from "prop-types"
import { AnimatedLink } from "@components/shared"

const Footer = () => {
  const { pathname } = useLocation()
  let windowWidth = null
  if (typeof window !== `undefined`) {
    windowWidth = window.innerWidth
  }
  const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(windowWidth)
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth)
    }

    useEffect(() => {
      window.addEventListener("resize", handleWindowSizeChange)
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange)
      }
    }, [windowWidth])

    return width <= 768
  }
  let mobile = false
  const isMobile = useCheckMobileScreen()
  if (isMobile) {
    mobile = isMobile
  }

  console.log("mobile", mobile)
  return (
    <div className={app__footer}>
      <div className={app__footer__wrapper}>
        <div className={app__footer__wrapper__content}>
          <img src={HelmetSVG} alt="logo"></img>
          <ul>
            <li>
              <AnimatedLink to={"/"}>Strona główna</AnimatedLink>
            </li>
            <li>
              <AnimatedLink to="/#meet">O mnie</AnimatedLink>
            </li>
            <li>
              <AnimatedLink to="/#contact">Kontakt</AnimatedLink>{" "}
            </li>
          </ul>
          <ul>
            <li>
              <AnimatedLink to={"/regulamin-sklepu"}>
                Dokumenty prawne
              </AnimatedLink>
            </li>
          </ul>
        </div>
        <div className={app__footer__wrapper__social}>
          <a href="https://github.com/Przemocny/" target="_blank">
            <img src={GithubSVG} alt="github"></img>
          </a>
          <a
            href="https://www.facebook.com/locahostacademyjavascriptandtypescript"
            target="_blank"
          >
            <img src={FacebookSVG} alt="fb"></img>
          </a>
        </div>
      </div>
      <div className={app__footer__copyFoot}>
        &copy; Copyright 2020 by Localhost Group sp.z.o.o
      </div>
    </div>
  )
}

export default Footer

{
  /* {mobile ? (
            <React.Fragment>
            <div className={app__footer__wrapper__mobile}>
            <img src={HelmetSVG}></img>
            <div className={app__footer__wrapper__mobile__icons}>
              <a>
                <img src={GithubSVG}></img>
              </a>
              <a>
                <img src={FacebookSVG}></img>
              </a>
            </div>
          </div>
          <div className={app__footer__wrapper__mlinks}>
            <div className={app__footer__wrapper__mlinks__regulations}>
              <li>
                <AnimatedLink to={"/"}>Strona główna</AnimatedLink>
              </li>
              <li>
                <AnimatedLink to={"#meet"}>O mnie</AnimatedLink>
              </li>
              <li>
                <AnimatedLink>Kontakt</AnimatedLink>{" "}
              </li>
            </div>
            <div className={app__footer__wrapper__mlinks__regulations}>
              <li>
                <AnimatedLink>Regulamin sklepu</AnimatedLink>
              </li>
              <li>
                <AnimatedLink>Regulamin Zwrotów</AnimatedLink>
              </li>
              <li>
                <AnimatedLink>Rodo</AnimatedLink>
              </li>
              <li>
                <AnimatedLink>Polityka prywatności</AnimatedLink>
              </li>
            </div>
          </div>
            </React.Fragment>
          ):(
            <React.Fragment> */
}
