/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const siteBasic = {
  title: "",
  description: "",
  lang: "",
}

const seoBasic = {
  title: "Page",
  opengraphTitle: "",
  metaKeywords: "",
  metaDesc: "",
  metaRobotsNofollow: "",
  metaRobotsNoindex: "",
  opengraphDescription: "",
  opengraphImage: null,
}

function Head({ siteMetaData = {}, seo = {}, ogType = "website", location }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: siteMetaData.language || siteBasic.lang,
      }}
      title={`${seo.title}`}
      meta={[
        {
          name: "description",
          content: seo.opengraphDescription || siteMetaData.description,
        },
        {
          name: "keywords",
          content: seo.focuskw || "",
        },
        {
          property: "og:title",
          content: seo.title || siteMetaData.title || siteBasic.description,
        },
        {
          property: "og:description",
          content:
            seo.opengraphDescription ||
            siteMetaData.description ||
            siteBasic.description,
        },
        {
          property: "og:image",
          content: seo.opengraphImage ? seo.opengraphImage.sourceUrl : "",
        },
        {
          property: "og:image:alt",
          content: seo.title,
        },
        {
          property: "og:type",
          content: ogType,
        },
        {
          property: "og:url",
          content: location.href,
        },
      ]}
    >
      {/* favicon */}
      <link
        rel="icon"
        href="https://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/05/gladiators_logo.png"
      />

      <link rel="preconnect dns-prefetch" href="https://cdnjs.cloudflare.com" />
      <link rel="preconnect dns-prefetch" href="https://fonts.googleapis.com" />

      <link
        rel="preload"
        as="style"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/css/bootstrap-grid.min.css"
      />
      <link
        rel="preload"
        type="text/css"
        as="style"
        href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/css/bootstrap-reboot.min.css"
      />
      <link
        rel="preload"
        as="font"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap"
      />
      <link
        rel="preload"
        as="font"
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap"
      />
      <link
        rel="preload"
        as="font"
        href="https://fonts.googleapis.com/css2?family=Cantarell:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link
        rel="preload"
        as="font"
        href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;1,100&display=swap"
        rel="stylesheet"
      />
      <link
        rel="preload"
        as="font"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      {/* <script
        async
        defer
        type="text/javascript"
        src="https://kit.fontawesome.com/0e40483b21.js"
        crossOrigin="anonymous"
      /> */}
      <script
        async
        defer
        src="https://pixel.fasttony.com/64a69e96d89f4790919dbc5cd2c32a85"
      ></script>
      {/* PIXEL */}
      <script async defer type="text/javascript">
        {`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1227629290973044');
      fbq('track', 'PageView');`}
      </script>
      <noscript>
        {`
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=1227629290973044&ev=PageView&noscript=1"
        />`}
      </noscript>

      <script async defer type="text/javascript">
        {`(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:1956375,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </script>
      {/* GA */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-64745800-16"
      ></script>
      <script async defer type="text/javascript">
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-64745800-16')
      `}
      </script>
      <script defer src="https://apis.google.com/js/api.js" />
    </Helmet>
  )
}

export default Head
