import React from "react"
import { useSpring, animated } from "react-spring/"
import { useIntersectionObserver } from "@components/logic"

import { IAnimatedSection } from "./types"

const randomizer = (border = 40) => -1 * border + 2 * Math.random() * border

const animationStates = (inView: boolean) =>
  !inView
    ? {
        opacity: 0,
        transform: [randomizer(), randomizer()],
      }
    : {
        opacity: 1,
        transform: [0, 0],
      }

const Section: React.FC<IAnimatedSection> = ({
  children,
  className,
  style = {},
}) => {
  const [{ inView }, marktoObserve] = useIntersectionObserver()
  const { opacity, transform } = useSpring({
    config: { mass: 2, tension: 400, friction: 36 },
    ...animationStates(inView),
  })

  return (
    <animated.div
      ref={marktoObserve}
      className={className}
      style={{
        ...style,
        transform: inView
          ? transform.interpolate(
              (x, deg) =>
                `translate3d(${x}%, 0, 0) rotate3d(1, 1, 1, ${deg}deg)`
            )
          : "none",
        opacity: inView ? opacity.interpolate((x) => x) : 0,
      }}
    >
      {children}
    </animated.div>
  )
}

export default Section
