import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "@reach/router"
import { Header, Head, Footer } from "@components/layout"
// import Main from './Main'
import SideLayout from "./SideLayout"
import { main } from "./layout.module.scss"
import {
  plam__left__first,
  plam__left__second,
  plam__left__third,
  plam__right__second,
  plam__right__first,
  plam__right__third,
  small,
} from "./layout.module.scss"
import RightSVG from "./plam.svg"
import LeftSVG from "./plamleft.svg"
import { AnimatedButton, Modal } from "@components/shared"
const PlamTable = [
  {
    svg: RightSVG,
    className: plam__right__first,
  },
  {
    svg: LeftSVG,
    className: plam__left__first,
  },
  {
    svg: RightSVG,
    className: plam__right__second,
  },
  {
    svg: LeftSVG,
    className: plam__left__second,
  },
  {
    svg: RightSVG,
    className: plam__right__third,
  },
  {
    svg: LeftSVG,
    className: plam__left__third,
  },
]

const Layout = ({ children, siteMetaData, seo, imBusy }) => {
  const [plams, setPlams] = useState(0)
  const modal = useRef()
  useEffect(() => {
    if (modal.current) {
      const ls = localStorage.getItem("test")
      if (!ls) {
        modal.current.toggle()
      }
    }
    setPlams(Math.ceil(window.document.body.clientHeight / 1550))
  }, [])

  const location = useLocation()

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <Modal
        ref={modal}
        ref={modal}
        title={false}
        close={false}
        isDev={true}
        lockBackground={true}
        bgColor="rgba(0, 0, 0, 0.2)"
      >
        <h3 style={{ textAlign: "center", fontWeight: 600 }}>
          Ta strona jest jest w trakcie budowy, jeśli chcesz dołączyć do
          gladiatorów skontaktuj się z nami przez formularz kontaktowy.
        </h3>
        <AnimatedButton
          className={small}
          onClick={() => {
            localStorage.setItem("test", true)
            modal.current.toggle(false)
          }}
        >
          Chcesz potestować?
        </AnimatedButton>
      </Modal>
      <Head seo={seo} siteMetaData={siteMetaData} location={location} />
      <Header />

      {[...Array(plams).keys()].map((_, i) => {
        if (typeof PlamTable[i] !== "undefined")
          return (
            <img
              src={PlamTable[i].svg}
              className={PlamTable[i].className}
              key={i}
            />
          )
      })}

      <main className={main}>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
