import React from "react"
import PropTypes from "prop-types"
import { getSortedSrcSet, getCssSizes, getClosestValue } from "./logic"

const placeholder =
  "https://via.placeholder.com/300x300.png?text=Nie+ma+obrazka"

class ResizedImage extends React.Component {
  state = {
    sortedSrcSet: [],
    sourceUrl: "",
  }

  static defaultProps = {
    size: "150w",
    altText: "Image on page",
  }

  static getDerivedStateFromProps(props, current_state) {
    if (
      typeof props.srcSet !== "string" ||
      typeof props.sourceUrl !== "string"
    ) {
      return {
        sourceUrl: placeholder,
        sortedSrcSet: [placeholder],
      }
    }

    if (current_state.sourceUrl !== props.sourceUrl) {
      const { srcSet, size } = props
      const sortedUrlSources = getSortedSrcSet(srcSet)

      let selectedImage = getClosestValue(sortedUrlSources, size)

      if (selectedImage) {
        return {
          sourceUrl: selectedImage[0],
          sortedSrcSet: sortedUrlSources,
        }
      } else {
        selectedImage = sortedUrlSources[0]
        return {
          sourceUrl: selectedImage[0],
          sortedSrcSet: sortedUrlSources,
        }
      }
    }
    return null
  }

  render() {
    const { altText, style = {} } = this.props
    const { sourceUrl } = this.state
    return <img loading="lazy" alt={altText} src={sourceUrl} style={style} />
  }
}

ResizedImage.propTypes = {
  altText: PropTypes.string,
  size: PropTypes.string,
  srcSet: PropTypes.string.isRequired,
  sourceUrl: PropTypes.string.isRequired,
}

export default ResizedImage
